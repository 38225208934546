export const HayPostIcon = ({ size = "16", margin }) => {
  return (
    <svg
      width={size}
      height={size}
      style={{ margin }}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2845_290)">
        <path
          d="M4.56718 12.5136C4.72163 12.0364 4.86796 11.5835 5.01464 11.1309C5.05452 11.0084 5.09203 10.885 5.13698 10.7643C5.27115 10.4051 5.52462 10.1852 5.91293 10.1626C6.24988 10.1431 6.58818 10.1485 6.9258 10.1505C7.03699 10.1511 7.09579 10.1184 7.13229 10.0058C7.53142 8.76808 7.93596 7.53171 8.33847 6.29501C8.3503 6.25926 8.35435 6.22082 8.3655 6.16618C8.27425 6.16213 8.19754 6.15573 8.12048 6.15573C6.3124 6.15505 4.50432 6.14662 2.69658 6.15977C2.04499 6.16483 1.54988 5.93179 1.18083 5.38949C0.811102 4.84685 0.410958 4.32445 0 3.75955C0.0936147 3.75955 0.156813 3.75955 0.220011 3.75955C3.12646 3.75955 6.03257 3.75787 8.93902 3.7636C9.10699 3.76394 9.18708 3.72347 9.23473 3.55585C9.33646 3.19735 9.34626 3.20005 9.71768 3.20005C10.3429 3.20005 10.9681 3.19971 11.5934 3.20073C11.6647 3.20073 11.736 3.20848 11.8276 3.21388C11.7718 3.40746 11.7218 3.58148 11.6613 3.79092C12.0226 3.79092 12.3541 3.77439 12.6833 3.79395C13.6992 3.85432 14.6039 4.18348 15.2754 4.9804C16.0091 5.85152 16.1572 6.8734 15.8476 7.93136C15.1659 10.2614 13.6167 11.7214 11.253 12.3069C10.645 12.4573 10.0235 12.5149 9.39662 12.5139C7.86465 12.5119 6.33268 12.5133 4.80071 12.5133C4.73346 12.5136 4.6662 12.5136 4.56718 12.5136ZM9.58418 10.1377C10.1898 10.1734 10.7532 10.1393 11.2949 9.93767C12.2889 9.56838 13.0138 8.9222 13.3305 7.8892C13.5795 7.07676 13.2199 6.42283 12.3889 6.24139C11.956 6.14662 11.496 6.17495 11.0489 6.1419C10.9164 6.13212 10.8657 6.18844 10.8272 6.31019C10.5518 7.17524 10.2679 8.03793 9.98669 8.90096C9.85523 9.30465 9.72376 9.70834 9.58418 10.1377Z"
          fill="#FA4616"
        />
        <path
          d="M2.32568 6.96645C3.87793 6.96645 5.38895 6.96645 6.92024 6.96645C6.80195 7.3327 6.68907 7.68311 6.57518 8.03351C6.44912 8.4227 6.32475 8.81256 6.19261 9.19973C6.17436 9.25301 6.11691 9.3326 6.07737 9.3326C5.57719 9.33395 5.07566 9.34036 4.57683 9.309C4.2338 9.28741 3.93065 9.11879 3.72179 8.84932C3.25169 8.24126 2.80457 7.61532 2.32568 6.96645Z"
          fill="#FA4616"
        />
        <path
          d="M5.64471 14.5697C5.80119 14.0874 5.94854 13.6297 6.1013 13.1741C6.11144 13.1438 6.1645 13.108 6.19795 13.1077C6.99554 13.104 7.79312 13.1046 8.61538 13.1046C8.54508 13.3215 8.48222 13.5171 8.41835 13.7124C8.33656 13.9626 8.25781 14.2135 8.16893 14.4611C8.15305 14.5053 8.09289 14.5643 8.05267 14.5646C7.25948 14.5714 6.46663 14.5697 5.64471 14.5697Z"
          fill="#FA4616"
        />
      </g>
      <defs>
        <clipPath id="clip0_2845_290">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
