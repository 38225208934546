export const DelivoIcon = ({ size = "16", margin }) => {
  return (
    <svg
      width={size}
      height={size}
      style={{ margin }}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2845_209)">
        <path
          d="M19.6659 10.8793C19.8263 12.0431 20.8906 12.8824 22.6174 12.8824C23.5209 12.8824 24.7055 12.5582 25.2676 12.0048L26.8337 13.4737C25.7898 14.5038 24.083 15 22.5773 15C19.1639 15 17.136 12.9968 17.136 9.98292C17.136 7.12136 19.1838 5.0612 22.3964 5.0612C25.7094 5.0612 27.7774 7.00696 27.396 10.8793H19.6659ZM25.0469 8.95268C24.8862 7.73184 23.8822 7.12136 22.4769 7.12136C21.1515 7.12136 20.0672 7.73176 19.706 8.95268H25.0469ZM31.7131 1.4176V14.7519H29.2836V1.4176H31.7131ZM36.5319 2.69574C36.5319 4.50788 33.6405 4.50788 33.6405 2.69574C33.6405 0.883471 36.5319 0.883471 36.5319 2.69574ZM33.8614 5.30912V14.7519H36.3109V5.30912H33.8614ZM48.7797 5.32816L44.5631 14.79H41.9129L37.6764 5.32816H40.3468L41.7121 8.4376L43.238 12.4055L44.7439 8.47576L46.1092 5.32816H48.7797ZM59 10.0591C59 12.7679 57.0521 14.9618 53.8397 14.9618C50.6273 14.9618 48.6997 12.7679 48.6997 10.0591C48.6997 7.36932 50.6675 5.15652 53.8198 5.15652C56.9721 5.15652 59 7.36928 59 10.0591ZM51.1493 10.0591C51.1493 11.4898 52.0527 12.8252 53.8397 12.8252C55.6267 12.8252 56.5306 11.4897 56.5306 10.0591C56.5306 8.64744 55.4861 7.27392 53.8397 7.27392C52.073 7.27392 51.1493 8.64744 51.1493 10.0591ZM8.36769 1H1V7.45952L3.8654 5.88464V5.88052L7.72245 3.76478L7.7294 3.76098L11.5933 5.88052V10.1196L7.7294 12.239L4.87471 10.6732L1.00003 12.8028V15L8.36769 15C12.4368 15 15.7354 11.866 15.7354 8C15.7354 4.13401 12.4368 1 8.36769 1ZM8.29632 8.30828V10.8859L10.6458 9.59708V7.01952L8.29632 8.30828ZM7.16244 8.30828L4.81286 7.01952V9.59708L7.16244 10.8858V8.30828ZM3.8654 6.98884L1 8.56368V9.579L3.8654 8.00412V6.98884ZM3.8654 10.1195V9.10836L2.3375 9.94808L1 10.6832V11.6985L3.86919 10.1216L3.8654 10.1195Z"
          fill="#EECC18"
        />
      </g>
      <defs>
        <clipPath id="clip0_2845_209">
          <rect
            width="15"
            height="14"
            fill="white"
            transform="translate(1 1)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
